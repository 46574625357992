<template>
  <div class="page-item-wrap">
    <main class="sub __event">
      <div class="inner __event-wrap jobAppli">
        <div class="tit">
          <h1>신청하기</h1>
          <p class="stit">맞춤 일자리 신청</p>
        </div>
        <div class="sub-cont">
          <h2 class="h2-join">신청자 정보</h2>
          <div class="form-wrap">
            <div class="input-form">
              <div class="t-head">이름<i class="required"></i></div>
              <input
                type="text"
                class="text read"
                ref="userNm"
                v-model="userNm"
              />
            </div>
            <div class="input-form mobile-different-form">
              <div class="t-head">성별<i class="required"></i></div>
              <div class="rightForm">
                <li
                  v-for="(genderItem, idx) in OPTION_GENDER"
                  :key="genderItem.name"
                >
                  <input
                    v-model="gender"
                    type="radio"
                    class="checkbox"
                    :ref="'genderCode_' + idx"
                    :id="genderItem.value"
                    :value="genderItem.value"
                    name="gender"
                  />
                  <label :for="genderItem.value">{{ genderItem.name }}</label>
                </li>
              </div>
            </div>
            <div class="input-form">
              <div class="t-head">연락처<i class="required"></i></div>
              <input
                type="number"
                class="text read"
                ref="phone"
                v-model="phone"
              />
            </div>
            <div
              class="input-form different-input-form mobile-different-form"
              style="display: flex"
            >
              <div class="t-head" style="width: 132px">
                회원 사진<i class="required"></i>
              </div>
              <input
                type="text"
                class="inp addr1 file-value text"
                v-model="photoFileName"
                readonly
              />
              <input
                type="file"
                ref="fileInput"
                accept="image/*"
                class="hidden"
                @change="onFileChanged"
              />
              <button
                type="button"
                id="btn-file-upload"
                class="btn-func"
                ref="photoFileName"
                @click="onUploadClick"
              >
                사진업로드
              </button>
              <div class="profile-item-wrap">
                <div
                  class="pic"
                  style="border: 1px solid #ddd"
                  :style="userImg"
                ></div>
                <span class="photoSpan"
                  >본인의 증명사진을 업로드해주세요.<br />업로드된 사진은
                  담당자에게 공개되며<br />실제 본인의 사진이 아닐 경우<br />
                  불이익이 발생할 수 있습니다.</span
                >
              </div>
            </div>
            <div class="input-form" @click="findAddress">
              <div class="t-head" style="width: 132px">
                주소<i class="required"></i>
              </div>
              <input
                type="text"
                v-model="address"
                class="inp addr1 text"
                ref="address"
                readonly
              />
              <button type="button" class="btn-func">주소찾기</button>
            </div>
            <div class="input-form">
              <div class="t-head">나머지 주소<i class="required"></i></div>
              <input
                type="text"
                class="text read"
                v-model="addressDetail"
                ref="addressDetail"
              />
            </div>
            <div class="input-form different-input-form">
              <div class="t-head" style="width: 132px">
                신청지역<i class="required"></i>
              </div>
              <div class="checkCon region-wrap">
                <div v-for="(reg, idx) in regionList" :key="`reg_${idx}`">
                  <input
                    type="checkbox"
                    class="checkbox"
                    :id="reg.val"
                    :value="reg.txt"
                    v-model="regions"
                    ref="regions"
                  />
                  <label :for="reg.val">{{ reg.txt }}</label>
                </div>
                <div class="etc-wrap">
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="etc"
                    v-model="etc"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="etc">기타</label>
                  <input
                    type="text"
                    class="text"
                    ref="regionEtc"
                    v-model="regionEtc"
                    @focus="arryRemove"
                    @blur="arryAdd"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="input-form different-input-form">
              <div class="t-head" style="width: 132px">
                희망직종<i class="required"></i>
              </div>
              <div class="checkCon">
                <select
                  class="select"
                  name=""
                  id=""
                  ref="appliPositionId"
                  v-model="appliPositionId"
                >
                  <option value="NONE">직종</option>
                  <option
                    v-for="(pos, idx) in positionList"
                    :key="`reg_${idx}`"
                    style="display: flex"
                    :value="pos.txt"
                  >
                    {{ `${pos.txt}` }}
                  </option>
                </select>
              </div>
            </div>
            <div class="input-form pay-form">
              <div class="t-head" style="width: 132px">
                고용 형태
                <i class="required"></i>
              </div>
              <div class="checkCon">
                <div v-for="(empl, idx) in emploList" :key="`emplo_${idx}`">
                  <input
                    type="radio"
                    class="text"
                    name="empl"
                    ref="empl"
                    v-model="employmentType"
                    :id="empl.val"
                    :value="empl.txt"
                  />
                  <label :for="empl.val">{{ empl.txt }}</label>
                </div>
              </div>
            </div>
            <div class="input-form">
              <div class="t-head" style="width: 132px">
                희망 월급
                <i class="required"></i>
              </div>
              <div class="checkCon" style="align-items: center">
                <input
                  type="text"
                  class="text"
                  style="width: 174px"
                  v-model="payAmt"
                  @keyup="payFormatter"
                /><span style="margin-left: 5px">원</span>
              </div>
            </div>
            <!-- <div class="range-wrap">
                <p class="min-max">
                  {{
                    `${
                      insertCommaToNumber(minVal) +
                      '원 ~ ' +
                      insertCommaToNumber(maxVal) +
                      '원'
                    }`
                  }}
                </p>
                <dual-range
                  :step="step"
                  :min="minFix"
                  :max="maxFix"
                  :sBarMaxValue="medianVal"
                  @update_sBarValues="sBarValues"
                  ref="dualRange"
                ></dual-range>
                <div class="Criteria">
                  <span>최소</span>
                  <span>{{ `${insertCommaToNumber(medianVal) + '원'}` }}</span>
                  <span>최대</span>
                </div>
              </div> -->
            <div class="input-form different-input-form">
              <div class="t-head" style="width: 132px">
                경력<i class="required"></i>
              </div>
              <div class="checkCon">
                <input
                  type="radio"
                  class="text"
                  id="Newcomer"
                  name="arebeitYn"
                  v-model="arebeitYn"
                  value="N"
                  checked
                />
                <label for="Newcomer">1년 이하</label>
                <input
                  type="radio"
                  class="text"
                  id="experienced"
                  name="arebeitYn"
                  v-model="arebeitYn"
                  value="Y"
                />
                <label for="experienced">1년 이상</label>
              </div>
            </div>
            <div
              class="input-form different-input-form mobile-different-form"
              style="align-items: start"
            >
              <div class="t-head" style="width: 132px">
                경력사항 및 자기소개<i class="required"></i>
              </div>
              <textarea
                v-model="motive"
                ref="motive"
                name="motive"
                id="motive"
                cols="30"
                rows="10"
              ></textarea>
              <p class="textarea-info">
                <span style="color: red; font-size: 14px; float: left"
                  >이모티콘은 사용하실 수 없습니다.</span
                >({{ wordCount }}
                자)
              </p>
            </div>
            <div class="check-box type01">
              <p class="check-wrap" :class="{ on: isOpenShow }">
                <input
                  type="checkbox"
                  id="c1-2"
                  v-model="privacyYn"
                  ref="checkOpen"
                  true-value="Y"
                  false-value="N"
                />
                <label
                  >개인 정보보호 정책
                  <span class="required"></span>
                </label>
                <i class="el-icon-arrow-down" @click="toggleOpen"></i>
              </p>
              <div class="agree-item-wrap" v-html="privacyTerms"></div>
            </div>
            <button class="btn" v-if="!isApplicationForm" @click="save">
              신청서 제출
            </button>
            <span class="saveCompletion" v-if="isApplicationForm">
              맞춤 일자리 신청이 완료되었습니다.
            </span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import appli from 'Mixins/appli'
import { OPTION_GENDER } from 'Configs/registJob'
import { privacy } from 'Configs/terms'
import { searchAddressGetResult } from 'Utils/message'
import { WORK_TIME } from 'Configs/registJob'
import {
  uploadUserImg,
  getBasicUserInfo,
  getEventRInfo,
  putEventRInfo,
} from 'Api/modules'
import { mapGetters } from 'vuex'
import { USER_TYPE } from 'Configs/user'
export default {
  mixins: [appli],
  components: {},
  props: {},
  data() {
    return {
      OPTION_GENDER,
      genderCode: OPTION_GENDER[0].value,
      wordCount: 0,
      privacyTerms: Object.freeze(privacy),
      WORK_TIME_LIST: Object.freeze(WORK_TIME),
      isOpenShow: false,
      check: {
        checkOpen: false,
      },
      address: '',
      addressDetail: '',
      zipcode: '',
      photoFileName: '',
      //data
      userNm: '', //이름
      gender: '', //성별
      phone: '', //연락처
      birthday: '', //생일
      email: '', //이메일
      photo: '', //사진
      arebeitYn: '', //알바 유무
      uniqueness: '', //건강 및 특이사항
      privacyYn: '', //개인정보 동의 여부
      regions: [], //신청지역
      regionEtc: '', //신청지역 기타
      appliPositionId: 'NONE',
      inputPhoto: null,
      motive: null,
      eventSeq: 3,
      eventId: 0,
      isApplicationForm: false,
      employmentType: '단기알바',
      payAmt: '',
      etc: 'N',
    }
  },
  watch: {
    etc() {
      if (this.etc == 'Y') {
        this.$refs.regionEtc.readOnly = false
      } else {
        this.$refs.regionEtc.readOnly = true
      }
    },
  },
  methods: {
    arryAdd() {
      this.regions.push(this.regionEtc)
    },
    arryRemove() {
      for (var i = 0; i < this.regions.length; i++) {
        if (this.regions[i] == this.regionEtc) {
          this.regions.splice(i, 1)
        }
      }
    },
    payFormatter() {
      this.payAmt = this.insertCommaToNumber(this.payAmt)
    },
    chkIsValidInput(saveData) {
      if (!saveData.userNm) {
        this.$toasted.error('이름을 입력해주세요.')
        this.$refs['userNm'].focus()
        return false
      }
      if (
        this.validSpecial(saveData.userNm) ||
        saveData.userNm.length <= 1 ||
        this.validNumber(saveData.userNm)
      ) {
        this.$toasted.error('이름 형식이 올바르지 않습니다.')
        this.$refs['userNm'].focus()
        return false
      }
      if (!this.gender) {
        this.$toasted.error('성별을 선택하세요.')
        return false
      }
      if (!saveData.phone) {
        this.$toasted.error('핸드폰 번호를 입력해주세요.')
        this.$refs['phone'].focus()
        return false
      }
      if (!this.validPhone(saveData.phone)) {
        this.$toasted.error('핸드폰 번호 형식이 올바르지 않습니다.')
        this.$refs['phone'].focus()
        return false
      }
      if (!this.photoFileName) {
        this.$toasted.error('사진을 등록하세요.')
        this.$refs['photoFileName'].focus()
        return false
      }
      if (this.regions.length == 0) {
        this.$toasted.error('신청지역을 선택해주세요.')
        return false
      }
      if (this.appliPositionId == 'NONE') {
        this.$toasted.error('희망직종을 선택해주세요.')
        this.$refs['appliPositionId'].focus()
        return false
      }
      if (!this.payAmt) {
        this.$toasted.error('희망 월급을 입력해주세요.')
        this.$refs['payAmt'].focus()
        return false
      }
      if (!this.arebeitYn) {
        this.$toasted.error('경력을 선택해주세요.')
        this.$refs['arebeitYn'].focus()
        return false
      }
      if (!this.motive) {
        this.$toasted.error('경력사항 및 자기소개를 입력해주세요.')
        this.$refs['motive'].focus()
        return false
      }
      if (this.validSpecial(saveData.motive)) {
        this.$toasted.error(
          '경력사항 및 자기소개에는 이모티콘, 특수문자를 사용하실 수 없습니다.',
        )
        this.$refs['motive'].focus()
        return false
      }
      if (this.privacyYn == 'N' || this.privacyYn == '') {
        this.$toasted.error('개인 정보 보호 정책에 동의해주세요.')
        // this.$refs['checkOpen'].focus()
        return false
      }
      return true
    },
    findAddress() {
      if (this.isApp) {
        searchAddressGetResult(({ zonecode, address }) => {
          this.zipcode = zonecode
          this.address = address
          this.addressDetail = ''
        })
      } else {
        //카카오 지도 발생
        new window.daum.Postcode({
          oncomplete: data => {
            //선택시 입력값 세팅
            const { zonecode, address } = data
            this.zipcode = zonecode
            this.address = address
            this.addressDetail = ''
          },
        }).open()
      }
    },
    onUploadClick() {
      this.$refs['fileInput'].click()
    },
    async onFileChanged(event) {
      // 1M
      var maxFileSize = 1 * 1024 * 1000
      const file = event.target.files[0]
      if (file) {
        // 파일 크기 제한
        if (file.size > maxFileSize) {
          this.$toasted.error('사진 크기는 1M이하만 가능합니다.')
          return false
        } else {
          this.$toasted.show('사진이 업로드되었습니다.')
        }

        //허용 파일 타입
        const isAcceptable = [
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/gif',
        ].includes(file.type)
        if (isAcceptable) {
          // todo:: 파일업로드 api 태우고, 파일값 리턴받음.

          this.photoFileName = file.name

          try {
            const res = await uploadUserImg({ uploadFile: file })
            this.photo = res
            var reader = new FileReader()
            reader.onload = e => {
              this.inputPhoto = e.target.result
            }
            reader.readAsDataURL(file)
            this.isDelete = false
          } catch (e) {
            console.error(e)
            // this.deleteAttachedFile()
            return
          }
        }
        // else {
        //   this.deleteAttachedFile()
        // }
      }
    },
    async fetchPersonalInfo() {
      const res = await getBasicUserInfo()
      this.email = res.email
      this.address = res.address
      this.zipcode = res.zipcode
      this.addressDetail = res.addressDetail
      this.userNm = res.userNm
      this.photo = res.photo
      this.photoFileName = res.photoFileName
      this.phone = res.phone
      this.birthday = res.birthday || ''
      this.gender = res.gender ? res.gender : 'NONE'
    },
    async fetchEventRInfo() {
      const res = await getEventRInfo({
        eventSeq: this.eventSeq,
        userId: this.account.userId,
      })
      if (res) {
        this.eventId = res.eventId
        this.eventSeq = res.eventSeq
        this.userNm = res.userNm
        this.gender = res.gender ? res.gender : 'NONE'
        this.phone = res.phone
        this.birthday = res.birthday || ''
        this.email = res.email
        this.photo = res.photo
        this.photoFileName = res.photoFileName
        this.address = res.address
        this.addressDetail = res.addressDetail
        this.zipcode = res.zipcode
        this.uniqueness = res.uniqueness
        this.arebeitYn = res.arebeitYn
        this.motive = res.motive
        this.privacyYn = res.privacyYn
        this.appliPositionId = res.jobPositionNames
        this.regions = res.region.split(',')
        this.regionEtc = this.regionEtcFiter
        this.payAmt = res.payAmt
        this.regionEtc === ''
          ? (this.$refs.regionEtc.readOnly = true)
          : (this.$refs.regionEtc.readOnly = false)
        this.regionEtc === '' ? (this.etc = 'N') : (this.etc = 'Y')
      } else {
        await this.fetchPersonalInfo()
      }
    },
    async save() {
      let saveData = {}
      saveData = {
        eventId: this.eventId,
        eventSeq: this.eventSeq,
        userId: this.account.userId,
        userNm: this.userNm ? this.userNm : '',
        gender: this.gender,
        phone: this.phone ? this.phone : '',
        birthday: this.birthday ? this.birthday : '',
        email: this.email ? this.email : '',
        photo: this.photo ? this.photo : '0',
        address: this.address ? this.address : '',
        addressDetail: this.addressDetail ? this.addressDetail : '',
        zipcode: this.zipcode ? this.zipcode : '',
        uniqueness: this.uniqueness ? this.uniqueness : '',
        arebeitYn: this.arebeitYn ? this.arebeitYn : 'N',
        motive: this.motive ? this.motive : '',
        privacyYn: this.privacyYn ? this.privacyYn : 'N',
        jobPositionNames: this.appliPositionId,
        region: this.regions,
        employmentType: this.employmentType,
        payAmt: this.payAmt.split(',').join(''),
        availWorkingStartTime: this.availWorkingStartTime,
        availWorkingEndTime: this.availWorkingEndTime,
        instagram: 'N',
        facebook: 'N',
        blog: 'N',
        youtube: 'N',
      }
      if (!this.chkIsValidInput(saveData)) return false
      try {
        await putEventRInfo(saveData)
        this.isSaved = true
        this.$toasted.show('신청서 제출이 완료되었습니다.')
        this.isApplicationForm = true
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  computed: {
    ...mapGetters(['accountUserImg']),
    regionEtcFiter() {
      let chk_box = ['설악', '가평', '영등포', '강남', '소공동', '판교', '광교']
      let reg = this.regions.filter(x => !chk_box.includes(x))
      return reg
    },
    userImg() {
      let photo = ''
      if (this.isDelete) {
        photo =
          this.account.userType === USER_TYPE.EMPLOYEE
            ? require('assets/image/etc/etc-user-noimg.svg')
            : require('assets/image/etc/etc-hotel-noimg.svg')
      } else {
        photo = this.inputPhoto || this.accountUserImg
      }

      return `background: url(${photo}) no-repeat center center;`
    },
  },
  async created() {
    // 이벤트 신청 정보 조회
    await this.fetchEventRInfo()
    console.log(this.regionEtcFiter)
  },
}
</script>

<style scoped>
.divisionForm .text {
  width: 50% !important;
}
.divisionForm .t-head.t-sub {
  width: 85px !important;
}
.divisionForm .text.subtext {
  width: 34% !important;
}
.saveCompletion {
  font-weight: 700;
  color: #090909;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 3vw;
  font-size: 12pt;
}
#orderCheckCon {
  display: flex;
  flex-direction: column;
  height: auto;
}
#orderCheckCon div {
  width: 100%;
  display: flex;
  margin: 5px 0;
}
#orderCheckCon div label {
  width: 100%;
}
.checkCon {
  flex-wrap: wrap;
}
</style>
